import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import Pulse from "../pulse/pulse";
import AuthenticationService from "../service/authService";
import commonService from "../service/commonService";
import MarketPlaceService from "../service/marketplaceService";

export default function PostRegistration() {
  const dbkProfile = commonService.dbkProfile;
  const [searchParams] = useSearchParams();
  const token = searchParams.get("id_token") || "";
  const state = searchParams.get("state") || "";
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState<string>('');
  const { instance, accounts } = useMsal();
  const [isInvitation, setIsInvitation] = useState<boolean>(false);
  const [sub, setSub] = useState<string>('');

  useEffect(() => {
    if (token && state) {
      const stateSplit = state?.split(':');
      setIsInvitation(stateSplit[0]==='Invitation');
      setSub(stateSplit[1]);
      onB2CRegister(stateSplit[0], stateSplit[1]);
    }
  }, []);

  const onB2CRegister = (type: string, id: string) => {
    const model: b2cResiter = {
        IdToken: token,
        Id: id,
        type: type
    };
    
    AuthenticationService.b2cRegistration(model).then((response: any) => {
      commonService.showMessage(response?.data || {});
      const result = response?.data?.isSuccess;
      if(result) {
        activeSaasSubscription(id);
      } else {
        setLoading(false);
        setSuccess(false);
        setErrorMsg(response?.data?.exceptionMessage);
      }
    }).catch((e: any) => {
      toast.error(`${e?.response?.data?.message}`);
      setLoading(false);
    });
  };

  const activeSaasSubscription = (saasSubId: string) => {
    MarketPlaceService.getSaasSubscriptionFromDB(saasSubId).then((response: any) => {
      commonService.showMessage(response?.data || {});
      if (response?.data?.isSuccess){
        var request: MarketPlaceActivateSubscriptionRequest = {
          subscriptionId: saasSubId,
          planId: response.data.result.planId,
          quantity: response.data.result.quantity ?? 0
        }
        MarketPlaceService.activateSubscription(request).then((response: any) => {
          commonService.showMessage(response?.data || {});
          setLoading(false);
          if (response?.data?.isSuccess){
            setSuccess(true);
          }else{
            setSuccess(false);
          }
        })
      }else{
        setLoading(false);
        setSuccess(false);
        setErrorMsg(response?.data?.exceptionMessage);
      }
    })
  }

  const login = () => {
    localStorage.clear();
      if(accounts.length > 0){
        signOutClickHandler(accounts[0]?.homeAccountId);
      }
      sessionStorage.clear();
      navigate('/redirect');
  }

  function signOutClickHandler(homeAccountId: string) {
    const logoutRequest = {
      account: instance.getAccountByHomeId(homeAccountId),
      postLogoutRedirectUri: "/redirect",
    };
    instance.logoutRedirect(logoutRequest);
  }

  return (
    <>
      <div className="bg-white shadow">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="flex h-16 justify-between">
            <div className="flex">
              <div className="flex flex-shrink-0 items-center">
                <img
                  className="h-12 w-auto mt-4"
                  src={dbkProfile.imgUrl}
                  alt={dbkProfile.name}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-7xl px-6 py-8 sm:pt-8 lg:px-8">
        <div>
          <div className="text-center">
            <h1 className="mt-4 text-balance text-2xl font-semibold tracking-tight text-gray-900 sm:text-4xl">
              Register
            </h1>
            {loading ? (
              <Pulse show={loading} />
            ) : (
              <>
                {success ? 
                <p className="mt-6 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">
                  Registration is successful, please click the below button to login.
                </p>
                : 
                <p className="mt-6 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">
                  Registration is not successful: {errorMsg}, please contact support to complete registration.
                </p>}
                {success ? 
                  <div className="mt-10 flex items-center justify-center gap-x-6">
                    <a
                      href="#"
                      onClick={login}
                      className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Login
                    </a>
                  </div>
                  :
                  <div></div>
                }
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
