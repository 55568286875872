import { guestAxios } from "../../http-common";

const portalUrl = 'portal';

const resolveSubscription = async (body: MarketPlaceResolveSubscriptionRequest) => {
    return guestAxios.post<AuthenticationResponse>(`${portalUrl}/MarketPlace/ResolveSubscription`, body);
};

const activateSubscription = async (body: MarketPlaceActivateSubscriptionRequest) => {
    return guestAxios.post<AuthenticationResponse>(`${portalUrl}/MarketPlace/ActivateSubscription`, body);
};

const getSaasSubscriptionFromDB = async (saasSubId: string) => {
    return guestAxios.get<AuthenticationResponse>(`${portalUrl}/MarketPlace/GetSaasSubscriptionRecord/${saasSubId}`);
};

const MarketPlaceService = {
    resolveSubscription,
    activateSubscription,
    getSaasSubscriptionFromDB
};

export default MarketPlaceService;