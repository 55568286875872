import { guestAxios, secureAxios } from "../../http-common";

const authUrl = 'auth';
const portalUrl = 'portal';

const register = async (body: Register) => {
    return guestAxios.post<AuthenticationResponse>(`${authUrl}/Users/register`, body);
};

const authenticate = async (body: Login) => {
    return guestAxios.post<AuthenticationResponse>(`${authUrl}/Users/authenticate`, body);
};

const getUserById = async (userId: string) => {
    return secureAxios.get<AuthenticationResponse>(`${authUrl}/Users/${userId}`);
};

const updateImage = async (formData: FormData) => {
    return secureAxios.post<AuthenticationResponse>(`${authUrl}/Users/updateImage`, formData, {
        headers: {
          "content-type": "multipart/form-data",
        }
    });
};

const getProfileImage = async () => {
    return secureAxios.get<AuthenticationResponse>(`${authUrl}/Users/profileImage`);
};

const updateUserProfile = async (body: UpdateUser) => {
    return secureAxios.post<AuthenticationResponse>(`${authUrl}/Users/userProfile`, body);
};

const updatePassword = async (body: UpdatePasswordRequest) => {
    return secureAxios.post<AuthenticationResponse>(`${authUrl}/Users/password`, body);
};

const resendEmailVerificationToken = async () => {
    return secureAxios.post<AuthenticationResponse>(`${authUrl}/Users/ResendEmailVerificationToken`);
};

const getAccountById = async (id: string) => {
    return secureAxios.get<AuthenticationResponse>(`${authUrl}/Account/${id}`);
};

const updateAccount = async (account: CreateOrUpdateAccountRequest) => {
    return secureAxios.post<AuthenticationResponse>(`${authUrl}/Account`, account);
};

const getUserByIdSync = (userId: string) => {
    return secureAxios.get<AuthenticationResponse>(`${authUrl}/Users/${userId}`);
};

const verifyEmail = async (userId: string, token: string ) => {
    return guestAxios.get<any>(`${authUrl}/Users/VerifyEmail?userId=${userId}&token=${token}`);
}

const updateUserRole = (body: UpdateUserRoleRequest) => {
    return secureAxios.put<AuthenticationResponse>(`${authUrl}/role/bulkUpdate`, body);
};

const deleteAccount = async (accountId: string) => {
    return secureAxios.delete<AuthenticationResponse>(`${authUrl}/Account/${accountId}`);
};

const getAllUsers = async () => {
    return secureAxios.get<AuthenticationResponse>(`${authUrl}/Users`);
};

const authHealthcheck = async () => {
    return guestAxios.get<AuthenticationResponse>(`${authUrl}/healthcheck`);
}

const portalHealthcheck = async () => {
    return guestAxios.get<AuthenticationResponse>(`${portalUrl}/healthcheck`);
}

const grantAccess = async (body: grandAccessRequest) => {
    return secureAxios.put<AuthenticationResponse>(`${authUrl}/Account/AcknowledgeGrantAccess`, body);
};

const createCustomer = async (account: CreateOrUpdateAccountRequest, parentId: string) => {
    return secureAxios.put<AuthenticationResponse>(`${authUrl}/Account/createCustomer/${parentId}`, account);
};

const updateSalesStatus = async (account: CreateOrUpdateAccountRequest) => {
    return secureAxios.put<AuthenticationResponse>(`${authUrl}/Account/UpdateSalesStatus`, account);
};

const inviteUser = async (request: InviteUserRequest) => {
    return secureAxios.post<AuthenticationResponse>(`${authUrl}/Users/InviteUser`, request);
};

const getMsUserById = async (userId: string) => {
    return guestAxios.get<AuthenticationResponse>(`${authUrl}/Users/msUser/${userId}`);
};

const b2cRegistration = async (body: b2cResiter) => {
    return guestAxios.post<AuthenticationResponse>(`${authUrl}/Users/b2cRegistration`, body);
};

const AuthenticationService = {
    register,
    authenticate,
    getUserById,
    updateImage,
    getProfileImage,
    updateUserProfile,
    updatePassword,
    resendEmailVerificationToken,
    getAccountById,
    updateAccount,
    getUserByIdSync,
    verifyEmail,
    updateUserRole,
    deleteAccount,
    getAllUsers,
    authHealthcheck,
    portalHealthcheck,
    grantAccess,
    createCustomer,
    updateSalesStatus,
    inviteUser,
    getMsUserById,
    b2cRegistration,
};

export default AuthenticationService;